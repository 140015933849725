<template>
  <Toggle
    v-model="toggle"
    @change="handleToggleChange"
  >
    <template #icon="{ checked }">
      <i
        :class="checked ? 'fa fa-moon-o' : 'fa fa-sun-o'"
        style="color: #7366ff"
      ></i>
    </template>
  </Toggle>
</template>

<script setup>
import { ref, onMounted } from "vue"
import { useStore } from "vuex"
import Toggle from "../Toggle/Toggle.vue"

const store = useStore()
const mixLayout = ref("light-only")
const toggle = ref(false)

const initializeTheme = () => {
  const storedLayout = localStorage.getItem("mixLayout")
  mixLayout.value = storedLayout || "light-only"
  toggle.value = storedLayout === "dark-only"
  store.dispatch("layout/setLayout", { class: mixLayout.value })
}

const handleToggleChange = (newValue) => {
  mixLayout.value = newValue ? "dark-only" : "light-only"
  updateLayout()
}

const updateLayout = () => {
  localStorage.setItem("mixLayout", mixLayout.value)
  store.dispatch("layout/setLayout", { class: mixLayout.value })
}

onMounted(initializeTheme)
</script>

<style scoped>
i {
  cursor: pointer;
}
</style>
