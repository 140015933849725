<template>
  <div class="w-100">
    <div>
      <ShipmentWidget
        icon="boxes-stacked"
        :color="SHIPMENT_MODALS?.CAI?.color"
        :title="totalShipments"
        label="Total Shipments"
      />
    </div>
    <div class="d-flex flex-row card-modais gap-2">
      <ShipmentModal
        v-for="(modal, key) in SHIPMENT_MODALS"
        :key="key"
        icon="plane"
        :color="modal.color"
        :total="shipmentsTotalByModal[key]"
        :label="key"
      />
    </div>
  </div>
</template>

<script setup>
import { SHIPMENT_MODALS } from "@/constants/shipmentModals"
import { useHomeStore } from "../store/homeStore"
import { storeToRefs } from "pinia"
import ShipmentWidget from "../../../../components/ShipmentWidget.vue"
import ShipmentModal from "./ShipmentModal.vue"

const store = useHomeStore()
const { shipmentsTotalByModal, totalShipments } = storeToRefs(store)
</script>

<style scoped lang="scss">
:deep(.profile-box) {
  background: white;
}

.card-modais {
  .sh-modal {
    flex: 1;
  }
}
</style>
