export const data = [
  {
    class: "",
    code1: `<!-- Cod Box Copy begin -->
            <button type="button" class="btn btn-primary">primary button</button>
            <button type="button" class="btn btn-secondary">secondary button</button>
            <button type="button" class="btn btn-success">Success button</button>
            <button type="button" class="btn btn-info">Info button</button>
            <button type="button" class="btn btn-warning">warning button</button>
            <button type="button" class="btn btn-danger">danger button</button>
            <button type="button" class="btn btn-light">light button</button> 
            <!-- Cod Box Copy end -->`,
    desc: "",
    id: "defaul",
    info: "",
    number: 0,
    subTitle: "Bootstrap state buttons",
    title: "Default",
  },
  {
    class: "btn-lg",
    code1: `<!-- Cod Box Copy begin -->
            <button type="button" class="btn btn-primary btn-lg">primary button</button>
            <button type="button" class="btn btn-secondary btn-lg">secondary button< /button>
            <button type="button" class="btn btn-success btn-lg">Success button</button>
            <button type="button" class="btn btn-info btn-lg">Info button</button>
            <button type="button" class="btn btn-warning btn-lg">warning button</button>
            <button type="button" class="btn btn-danger btn-lg">danger button</button>
            <button type="button" class="btn btn-light btn-lg">light button</button> 
            <!-- Cod Box Copy end -->`,
    desc: ".btn-lg",
    id: "large",
    info: " large size buttons",
    number: 1,
    subTitle: "",
    title: "Large Buttons",
  },
  {
    class: "btn-sm",
    code1: `<!-- Cod Box Copy begin -->
            <button type="button" class="btn btn-primary btn-sm">primary button</button>
            <button type="button" class="btn btn-secondary btn-sm">secondary button</button>
            <button type="button" class="btn btn-success btn-sm">Success button</button>
            <button type="button" class="btn btn-info btn-sm">Info button</button>
            <button type="button" class="btn btn-warning btn-sm">warning button</button>
            <button type="button" class="btn btn-danger btn-sm">danger button</button>
            <button type="button" class="btn btn-light btn-sm">light button</button> 
            <!-- Cod Box Copy end -->`,
    desc: ".btn-sm",
    id: "small",
    info: "small size buttons",
    number: 2,
    subTitle: "",
    title: "Small Buttons",
  },
  {
    class: "btn-es",
    code1: `<!-- Cod Box Copy begin -->
            <button type="button" class="btn btn-primary btn-xs">primary button</button>
            <button type="button" class="btn btn-secondary btn-xs">secondary button</button>
            <button type="button" class="btn btn-success btn-xs">Success button</button>
            <button type="button" class="btn btn-info btn-xs">Info button</button>
            <button type="button" class="btn btn-warning btn-xs">warning button</button>
            <button type="button" class="btn btn-danger btn-xs">danger button</button>
            <button type="button" class="btn btn-light btn-xs">light button</button> 
            <!-- Cod Box Copy end -->`,
    desc: ".btn-xs",
    id: "extra-small",
    info: "extra small size buttons",
    number: 3,
    subTitle: "",
    title: "Extra Small Buutons",
  },
  {
    class: "active",
    code1: `<!-- Cod Box Copy begin -->
            <button type="button" class="btn btn-primary active">Active</button>
            <button type="button" class="btn btn-secondary active">Active</button>
            <button type="button" class="btn btn-success active">Active</button>
            <button type="button" class="btn btn-info active">Active</button>
            <button type="button" class="btn btn-warning active">Active</button>
            <button type="button" class="btn btn-danger active">Active</button>
            <button type="button" class="btn btn-light active">Active</button> 
            <!-- Cod Box Copy end -->`,
    desc: ".active",
    id: "active",
    info: "active state",
    number: 4,
    subTitle: "Bootstrap state buttons",
    title: "Active Buttons",
  },
  {
    class: "disabled",
    code1: `<!-- Cod Box Copy begin -->
            <button type="button" class="btn btn-primary disabled">Disabled</button>
            <button type="button" class="btn btn-secondary disabled">Disabled</button>
            <button type="button" class="btn btn-success disabled">Disabled</button>
            <button type="button" class="btn btn-info disabled">Info button</button>
            <button type="button" class="btn btn-warning disabled">Disabled</button>
            <button type="button" class="btn btn-danger disabled">Disabled</button>
            <button type="button" class="btn btn-light disabled">Disabled</button> 
            <!-- Cod Box Copy end -->`,
    desc: ".disabled",
    info: 'disabled="disabled" attribute for disabled button',
    number: 5,
    subTitle: "",
    title: "Disabled Buttons",
  },
]
