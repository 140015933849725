import Reports from "../Reports.vue";
import ViewReport from "../ViewReport.vue";

const reportsRoutes = [
	{
		component: Reports,
		meta: {
			title: "Reports",
		},
		name: "reports",
		path: "reports",
	},
	{
		component: ViewReport,
		meta: {
			title: "Report",
		},
		name: "report",
		path: "report",
	},
]

export default reportsRoutes