export default {
  about: "Sobre",
  accountLocked: "Sua conta está bloqueada. Entre em contato com o suporte.",
  actionFailed: "A ação não pôde ser concluída.",
  actions: "Ações",
  add: "Adicionar",
  address: "Endereço",
  adminPanel: "Painel Administrativo",
  airline: 'Cia. Aérea',
  airport: "Aeroporto",
  airportOfDestiny: "Aeroporto de Destino",
  airportOfOrigin: "Aeroporto de Origem",
  ata: "ATA",
  attachments: "Anexos",
  awb: "AWB",
  back: "Voltar",
  backToHomePage: "Voltar para a Página Inicial",
  billing: "Faturamento",
  billingCycle: "Ciclo de Faturamento",
  booking: 'Booking',
  cancel: "Cancelar",
  cancelSubscription: "Cancelar Assinatura",
  changesSaved: "Suas alterações foram salvas.",
  city: "Cidade",
  clear: "Limpar",
  client: "Cliente",
  close: "Fechar",
  company: "Empresa",
  compose: "Compor",
  confirm: "Confirmar",
  confirmPassword: "Confirmar senha",
  consignee: "Consignatário",
  contact: "Contato",
  country: "País",
  countryOfDestination: "País de Destino",
  countryOfDestiny: "País de Destino",
  countryOfOrigin: "País de Origem",
  creationDate: "Data de Criação",
  creditCard: "Cartão de Crédito",
  dashboard: "Painel",
  dateRange: "Intervalo de Datas",
  delete: "Excluir",
  destination: "Destino",
  destinination: "Destino",
  destiny: "Destino",
  downgradePlan: "Rebaixar Plano",
  download: "Baixar",
  downloadFailed: "Download falhou",
  downloadSuccess: "Download realizado com sucesso",
  draft: "Rascunho",
  edit: "Editar",
  email: "Email",
  emailIsRequired: "Email é obrigatório.",
  endDate: "Data de Término",
  enterAValidEmail: "Por favor, insira um email válido.",
  error: "Ocorreu um erro. Por favor, tente novamente mais tarde.",
  eta: "ETA",
  etaPredictive: "ETA Preditiva",
  etd:'ETD',
  export: "Exportar",
  failedSearchShipmentMovements: "Não encontramos nenhuma Transferência vinculada ao seu usuário.",
  filter: "Filtrar",
  filters: "Filtros",
  finalEta: "ETA Final",
  flightNumber: "Número do Vôo",
  forgotPassword: "Esqueceu a senha?",
  forward: "Encaminhar",
  from: "De",
  help: "Ajuda",
  home: "Início",
  house: "House",
  import: "Importar",
  inbox: "Caixa de Entrada",
  incoterm: "Incoterm",
  invalidCredentials: "Usuário ou senha inválidos.",
  invalidEmail: "Por favor, insira um email válido.",
  invalidPhone: "Por favor, insira um telefone válido.",
  invoice: "Fatura",
  invoices: "Faturas",
  jobTitle: "Cargo",
  language: "Idioma",
  loading: "Carregando, por favor, aguarde...",
  loadMore: "Carregar Mais",
  login: "Entrar",
  logOut: "Sair",
  logout: "Sair",
  markAsRead: "Marcar como Lida",
  markAsUnread: "Marcar como Não Lida",
  maxLength: "Deve ter menos de {count} caracteres.",
  mbl: "MBL",
  message: "Mensagem",
  minLength: "Deve ter pelo menos {count} caracteres.",
  month: "Mês",
  moreFilters: "Mais Filtros",
  name: "Nome",
  next: "Próximo",
  nextBillingDate: "Próxima Data de Faturamento",
  noEmails: "Nenhum email a ser exibido.",
  noResults: "Nenhum resultado encontrado.",
  notifications: "Notificações",
  open: "Abrir",
  optional: "(Opcional)",
  origin: "Origem",
  password: "Senha",
  passwordIsRequired: "Senha é obrigatória.",
  passwordsMismatch: "As senhas não coincidem.",
  paymentFailed: "Pagamento falhou, tente novamente.",
  paymentMethod: "Forma de Pagamento",
  paymentSuccessful: "Pagamento realizado com sucesso",
  phone: "Telefone",
  port: "Porto",
  portOfDestiny: "Porto de Destino",
  portOfOrigin: "Porto de Origem",
  postalCode: "CEP",
  profile: "Perfil",
  refClient: "Ref. Cliente",
  reference: "Referência",
  refGotrans: "Ref. Go-Trans",
  register: "Registrar",
  reject: "Rejeitar",
  reply: "Responder",
  reports: "Relatórios",
  required: "* Obrigatório",
  requiredField: "Este campo é obrigatório.",
  resetPassword: "Redefinir senha",
  result: "Resultado",
  retry: "Tentar Novamente",
  save: "Salvar",
  search: "Buscar",
  selectDate: "Selecionar Data",
  send: "Enviar",
  sent: "Enviados",
  sessionExpired: "Sua sessão expirou. Por favor, faça login novamente.",
  settings: "Configurações",
  shipIMO: "IMO do navio",
  shipment: "Embarque",
  shipName: "Navio",
  shipowner: "Armador",
  shipper: "Remetente",
  show: "Mostrar",
  signInWithFacebook: "Entrar com Facebook",
  signInWithGoogle: "Entrar com Google",
  simpleSearch: "Busca Simples",
  sorryYouDontHaveAccessToThisPage: "Desculpe, você não tem acesso a esta página.",
  startDate: "Data de Início",
  state: "Estado",
  status: "Status",
  subject: "Assunto",
  submit: "Enviar",
  submitForm: "Enviar Formulário",
  subscriptionPlan: "Plano de Assinatura",
  subscriptions: "Assinaturas",
  success: "Operação concluída com sucesso.",
  termsAcceptance: "Você deve aceitar os termos e condições.",
  timeline: "Linha do Tempo",
  to: "Até",
  today: "Hoje",
  tomorrow: "Amanhã",
  trackShipmentMovements: "Acompanhe as movimentações do embarque",
  trash: "Lixeira",
  travelNumber: "Número da viagem",
  update: "Atualizar",
  upgradePlan: "Atualizar Plano",
  upload: "Carregar",
  userManagement: "Gerenciamento de Usuários",
  username: "Usuário",
  week: "Semana",
  welcome: "Bem-vindo, {name}!",
  whatsNew: "O que há de novo!",
  year: "Ano",
  yesterday: "Ontem",
  yourCountToday: "Veja os eventos recentes na sua conta hoje.",
}
