<template>
  <div>
    <div class="clockbox">
      <svg
        id="clock"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 600 600"
        fill="white"
      >
        <g id="face">
          <circle
            class="circle"
            cx="300"
            cy="300"
            r="253.9"
          ></circle>
          <path
            class="hour-marks"
            d="M300.5 94V61M506 300.5h32M300.5 506v33M94 300.5H60M411.3 107.8l7.9-13.8M493 190.2l13-7.4M492.1 411.4l16.5 9.5M411 492.3l8.9 15.3M189 492.3l-9.2 15.9M107.7 411L93 419.5M107.5 189.3l-17.1-9.9M188.1 108.2l-9-15.6"
          ></path>
          <circle
            class="mid-circle"
            cx="300"
            cy="300"
            r="16.2"
          ></circle>
        </g>
        <g id="hour">
          <path
            class="hour-hand"
            :style="{ transform: `rotate(${hourPosition}deg)` }"
            d="M300.5 298V142"
          ></path>
        </g>
        <g id="minute">
          <path
            class="minute-hand"
            :style="{ transform: `rotate(${minutePosition}deg)` }"
            d="M300.5 298V67"
          ></path>
        </g>
        <g id="second">
          <path
            class="second-hand"
            :style="{ transform: `rotate(${secondPosition}deg)` }"
            d="M300.5 350V55"
          ></path>
        </g>
      </svg>
    </div>
      
    <div
      id="txt"
      class="badge f-10 p-0"
    >
      {{ time }}
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue"

const hourPosition = ref(0)
const minutePosition = ref(0)
const secondPosition = ref(0)

const time = ref("")

const updateClock = () => {
  const now = new Date()
  const seconds = now.getSeconds()
  const minutes = now.getMinutes()
  const hours = now.getHours()

  secondPosition.value = (seconds * 360) / 60 + 180
  minutePosition.value = (minutes * 360) / 60 + (seconds * 360) / 3600 + 180
  hourPosition.value = ((hours % 12) * 360) / 12 + (minutes * 360) / 720 + 180
}

onMounted(() => {
  const updateTime = () => {
    const date = new Date()
    const hr = date.getHours()
    const min = date.getMinutes().toString().padStart(2, "0")
    const ampm = hr >= 12 ? "PM" : "AM"
    const hr12 = hr % 12 || 12
    time.value = `${hr12}:${min} ${ampm}`
  }

  updateTime()
  const interval = setInterval(updateTime, 1000)
  onUnmounted(() => {
    clearInterval(interval)
  })
})

</script>
