<template>
  <div class="card widget-1">
    <div class="card-body">
      <div class="widget-content">
        <div
          class="widget-round primary"
          :style="{ borderColor: color }"
        >
          <div class="bg-round">
            <font-awesome-icon
              :icon="['fas', icon]"
              :color="color"
            />
            <svg
              class="half-circle"
              :style="{ fill: color }"
            >
              <use href="@/assets/svg/icon-sprite.svg#halfcircle"></use>
            </svg>
          </div>
        </div>
        <div>
          <h4>{{ title }}</h4>
          <span class="f-light">{{ label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    color: String,
    title: String,
    label: String,
  },
}
</script>

<style scoped lang="scss">
.card {
  background-color: var(--white) !important;
}

body.dark-only .card {
  background-color: #262932 !important;
}
</style>
