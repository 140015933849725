<template>
  <b-card>
    <b-row class="gap-2">
      <div>
        <b-text-view :label="$t('status')" />
        <span class="badge rounded-pill bg-primary f-14 m-0">
          {{ status }}
        </span>
      </div>
      <div
        v-for="(item, key) in textItems"
        :key="key"
      >
        <b-text-view
          :label="$t(key)"
          :text="item"
        />
      </div>
    </b-row>
  </b-card>
</template>

<script setup>
import { computed } from "vue"

const props = defineProps({
  shipment: {
    type: Object,
    required: true,
    default: () => ({
      status: "",
      refGotrans: "",
      house: "",
      reference: "",
      client: "",
      booking: "",
    }),
  },
  status: {
    type: String,
    required: true,
    default: "",
  },
})

const textItemsCAICAE = computed(() => {
  return {
    refGotrans: props.shipment?.refGotrans,
    client: props.shipment?.clientContact?.nameCg,
    refClient: props.shipment?.refClient,
    awb:
      props.shipment?.carrierContact?.codNumeric +
      "-" +
      props.shipment?.masterNumber,
    house: props.shipment?.houseNumber,
    incoterm: props.shipment?.incoterm?.code,
  }
})

const textItemsCMICME = computed(() => {
  return {
    refGotrans: props.shipment?.refGotrans,
    client: props.shipment?.clientContact?.nameCg,
    refClient: props.shipment?.refClient,
    booking: props.shipment?.booking,
    house: props.shipment?.houseNumber,
    incoterm: props.shipment?.incoterm?.code,
  }
})

const textItems = computed(() => {
  const modal = props.shipment?.shipmentModal
  return modal === "CAI" || modal === "CAE"
    ? textItemsCAICAE.value
    : textItemsCMICME.value
})
</script>

<style scoped>
.badge {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
