<template>
  <div class="col-xxl-4 col-sm-6 box-col-6">
    <div class="card profile-box">
      <div class="card-body">
        <div class="media">
          <div class="media-body">
            <div class="greeting-user">
              <h4 class="f-w-600 mb-0">
                {{ $t("welcome", { name: userInfo?.name }) }}
              </h4>
              <p>{{ $t("yourCountToday") }}</p>
              <div class="whatsnew-btn">
                <a class="btn btn-outline-white">{{ $t("whatsNew") }}</a>
              </div>
            </div>
          </div>
          <Clock />
        </div>
        <div class="cartoon">
          <img
            class="img-fluid"
            src="@/assets/images/dashboard/cartoon.svg"
            alt="vector women with leptop"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuthStore } from "@/auth/store/authStore"
import Clock from "@/components/Clock.vue"
import { storeToRefs } from "pinia"

const store = useAuthStore()
const { userInfo } = storeToRefs(store)
</script>

<style scoped>
:deep(.profile-box) {
  background: var(--theme-deafult);
}
</style>
