export const SHIPMENT_MODALS = {
  CAI: {
    color: "#7366ff",
  },
  CAE: {
    color: "#f73164",
  },
  CMI: {
    color: "#307DF8",
  },
  CME: {
    color: "#ffa2b6",
  },
}
