<template>
  <div class="kanban-board p-2">
    <div
      class="card widget-1 d-flex justify-content-between align-items-center flex-row p-2 mb-4"
    >
      <div>
        <font-awesome-icon
          :icon="iconType"
          class="mr-2"
          :style="{ color: color }"
        />
        <span class="f-light">
          {{ items?.title }}
        </span>
      </div>
      <span
        class="badge f-light"
        :style="{ background: color, color: 'white' }"
      >
        {{ items?.value?.length }}
      </span>
    </div>
    <div ref="root">
      <KanbanCard
        v-for="(value, index) in items?.value"
        :key="index"
        :index="index"
        :root="root"
        :target="target"
        :is-active="isActive"
        :shipment="value"
        :plane="value?.modal === 'CAI' || value?.modal === 'CAE'"
        :ship="value?.modal === 'CMI' || value?.modal === 'CME'"
      />
    </div>
    <footer></footer>
  </div>
</template>

<script setup>
import { computed } from "vue"
import KanbanCard from "./KanbanCard.vue"
import { defineProps } from "vue"
import { SHIPMENT_MODALS } from "@/constants/shipmentModals"
import { ref } from "vue"

const root = ref(null)

const props = defineProps({
  items: Object,
})

const iconType = computed(() => {
  const modal = props.items?.title
  return ["fas", modal === "CAI" || modal === "CAE" ? "plane" : "ship"]
})

const color = computed(() => {
  const modal = props.items?.title
  return SHIPMENT_MODALS[modal]?.color ?? "#7366ff"
})
</script>

<style scoped>
.kanban-board {
  max-width: 330px;
  min-width: 330px;
  box-shadow: none !important;
}

.card {
  box-shadow: none !important;
}
</style>
