import { createWebHistory, createRouter } from "vue-router"
import Body from "../components/body"

/* Dashboards */
import Home from "@/pages/dashboard/Home.vue"

/* Tracking */
import ShipmentMovements from "@/pages/tracking/SearchShipmentMovements.vue"
import Tracking from "@/pages/tracking/DetailsTracking.vue"

// error
import Error400 from "../pages/error/error400"
import Error401 from "../pages/error/error401"
import Error403 from "../pages/error/error403"
import Error404 from "../pages/error/error404"
import Error500 from "../pages/error/error500"
import Error503 from "../pages/error/error503"

/* Authentication */
import LoginOne from "../pages/authentication/login_one"
import LoginTwo from "../pages/authentication/login_two"
import LoginValidate from "../pages/authentication/login_validation.vue"
import LoginTooltip from "../pages/authentication/login_tooltip.vue"
import LoginSweetalert from "../pages/authentication/login_sweetalert.vue"

import RegisterImage from "../pages/authentication/register_image"
import RegisterImage2 from "../pages/authentication/register_image2"
import Unlock from "../pages/authentication/unlock"
import ForgetPassword from "../pages/authentication/forget_password"
import ResetPassword from "../pages/authentication/reset_password"
import Maintenance from "../pages/authentication/maintenance.vue"
/* Auth */
import login from "../auth/login"
import Register from "../auth/register"
import Cookies from "js-cookie"

import reportsRoutes from "@/pages/reports/routes"

import { setTokenHeaderAuthorization, isTokenExpired } from "@/utils/jwt"

const routes = [
  {
    path: "/",
    redirect: { name: "home" },
    meta: {
      title: "Go Trans - Portal de Soluções Logísticas",
      description:
        "Acesse o portal da Go Trans para informações sobre nossos serviços e soluções logísticas.",
      keywords: "logística, transporte, Go Trans, soluções logísticas",
    },
  },
  {
    path: "/tracking",
    children: [
      {
        component: ShipmentMovements,
        meta: {
          title: "Shipment Movements",
        },
        name: "shipmentMovements",
        path: "shipment-movements",
      },
      {
        component: Tracking,
        meta: {
          title: "Tracking",
        },

        name: "tracking",
        path: "shipment-movements/tracking",
      },
    ],
    component: Body,
  },
  {
    path: "/dashboard",
    children: [
      {
        component: Home,
        meta: {
          title: "Home - Portal de Soluções Logísticas",
          description:
            "Acesse o portal da Go Trans para informações sobre nossos serviços e soluções logísticas.",
          keywords: "logística, transporte, Go Trans, soluções logísticas",
        },
        name: "home",
        path: "home",
      },
      ...reportsRoutes,
    ],
    component: Body,
  },
  {
    component: Error400,
    meta: {
      title: "Error400 | Cuba - Premium Admin Template",
    },
    name: "Error400",
    path: "/error-400",
  },
  {
    component: Error401,
    meta: {
      title: "Error401 | Cuba - Premium Admin Template",
    },
    name: "Error401",
    path: "/error-401",
  },
  {
    component: Error403,
    meta: {
      title: "Error403 | Cuba - Premium Admin Template",
    },
    name: "Error403",
    path: "/error-403",
  },
  {
    component: Error404,
    meta: {
      title: "Error404 | Cuba - Premium Admin Template",
    },
    name: "Error404",
    path: "/error-404",
  },
  {
    component: Error500,
    meta: {
      title: "Error500 | Cuba - Premium Admin Template",
    },
    name: "Error500",
    path: "/error-500",
  },
  {
    component: Error503,
    meta: {
      title: "Error503 | Cuba - Premium Admin Template",
    },
    name: "Error503",
    path: "/error-503",
  },
  {
    component: LoginOne,
    meta: {
      title: "LoginOne | Cuba - Premium Admin Template",
    },
    name: "LoginOne",
    path: "/authentication/login/one",
  },
  {
    component: LoginTwo,
    meta: {
      title: "LoginTwo | Cuba - Premium Admin Template",
    },
    name: "LoginTwo",
    path: "/authentication/login/two",
  },
  {
    component: LoginValidate,
    meta: {
      title: "LoginValidate | Cuba - Premium Admin Template",
    },
    name: "LoginValidate",
    path: "/authentication/login/validate",
  },
  {
    component: LoginTooltip,
    meta: {
      title: "LoginTooltip | Cuba - Premium Admin Template",
    },
    name: "LoginTooltip",
    path: "/authentication/login/tooltip",
  },
  {
    component: LoginSweetalert,
    meta: {
      title: "LoginTooltip | Cuba - Premium Admin Template",
    },
    name: "LoginSweetalert",
    path: "/authentication/login/sweetalert",
  },
  {
    component: RegisterImage,
    meta: {
      title: "RegisterImage | Cuba - Premium Admin Template",
    },
    name: "RegisterImage",
    path: "/authentication/register/image",
  },
  {
    component: RegisterImage2,
    meta: {
      title: "RegisterImage2 | Cuba - Premium Admin Template",
    },
    name: "RegisterImage2",
    path: "/authentication/register/image2",
  },
  {
    component: Unlock,
    meta: {
      title: "Unlock | Cuba - Premium Admin Template",
    },
    name: "Unlock",
    path: "/authentication/unlockuser",
  },
  {
    component: ForgetPassword,
    meta: {
      title: "ForgetPassword | Cuba - Premium Admin Template",
    },
    name: "ForgetPassword",
    path: "/authentication/forgetpassword",
  },
  {
    component: ResetPassword,
    meta: {
      title: "ResetPassword | Cuba - Premium Admin Template",
    },
    name: "ResetPassword",
    path: "/authentication/resetpassword",
  },
  {
    component: Maintenance,
    meta: {
      title: "ResetPassword | Cuba - Premium Admin Template",
    },
    name: "Maintenance",
    path: "/authentication/maintenance",
  },
  {
    children: [
      {
        component: login,
        meta: {
          title: " login | Go-Trans",
          public: true,
        },
        name: "login",
        path: "login",
      },
      {
        component: Register,
        meta: {
          title: " Register | Cuba - Premium Admin Template",
        },
        name: "register 1",
        path: "register",
      },
    ],
    path: "/auth",
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) document.title = to.meta.title
  const path = ["/auth/login", "/auth/register"]

  const accessToken = Cookies.get("accessToken")

  if (path.includes(to.path)) return next()

  if (accessToken && !isTokenExpired(accessToken)) {
    setTokenHeaderAuthorization(accessToken)
    return next()
  }
  next("/auth/login")
})

router.afterEach(() => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  })
})

export default router
