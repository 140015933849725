<template>
  <div
    v-if="!loading"
    class="div-report"
  >
    <div v-if="reportUrl">
      <iframe
        :src="reportUrl"
        width="100%"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
    <div v-else>
      <Error401 />
    </div>
    <div class="d-flex justify-content-end mb-4">
      <b-button
        outline
        @click="goBack"
      >
        {{ $t("back") }}
      </b-button>
    </div>
  </div>
</template>

<script setup>
import { useLoader } from "@/composables/useLoader"
import { storeToRefs } from "pinia"
import Error401 from "../error/error401.vue"
import { useReportsStore } from "./store/reportsStore"
import router from "@/router"
import { onMounted } from "vue"
import { useRoute } from "vue-router"
import powerEmbeddedService from "./service/powerEmbeddedService"
import { toast } from "vue3-toastify"
import { useAuthStore } from "@/auth/store/authStore"

const route = useRoute()

const { loading } = useLoader()

const store = useReportsStore()
const { reportUrl } = storeToRefs(store)

const Authstore = useAuthStore()
const { userInfo } = storeToRefs(Authstore)

onMounted(async () => {
  const reportId = route?.query?.report

  if (reportId) {
    const response = await powerEmbeddedService.getReportUrl(
      userInfo.value.email,
      reportId
    )

    reportUrl.value = response.data?.embedUrl
    return
  }

  toast.error(t("error"))
  goBack()
})

const goBack = () => {
  router.push({ name: "reports" })
}
</script>

<style>
iframe {
  height: 100vh;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
}

.div-report {
  width: 100%;
  height: 100%;
}
</style>
