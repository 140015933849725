<template>
  <div>
    <Breadcrumbs
      main="Dashboard"
      title="Home"
    />

    <div class="container-fluid">
      <div
        style="margin-bottom: 50px !important"
        class="d-flex flex-column flex-xxl-row align-items-center gap-4"
      >
        <WelcomeCard />
        <TotalShipmentsCard />
      </div>

      <CardSearch />
      <perfect-scrollbar
        class="visible-scroll scroll-demo"
        :settings="options"
      >
        <div
          class="row widget-grid gap-2 mt-5 d-flex justify-content-center justify-content-md-between flex-row flex-nowrap"
          style="min-width: 1260px"
        >
          <KanbanBoard
            v-for="(item, index) in shipmentsByModal"
            :key="index"
            :items="item"
            style="height: max-content !important"
          />
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue"
import KanbanBoard from "./home/components/KanbanBoard.vue"
import WelcomeCard from "./home/components/WelcomeCard.vue"
import { useHomeStore } from "./home/store/homeStore"
import { storeToRefs } from "pinia"
import CardSearch from "./home/components/CardSearch.vue"
import TotalShipmentsCard from "./home/components/TotalShipmentsCard.vue"
import { ref } from "vue"

const store = useHomeStore()

const { shipmentsByModal } = storeToRefs(store)
const { fetchShipments } = store

const options = ref({
  maxScrollbarLength: 60,
  suppressScrollY: false,
  suppressScrollX: true,
})

onMounted(() => {
  fetchShipments()
})
</script>
