export var menuItems = {
  data: [
    {
      headTitle1: "General",
      headTitle2: "Dashboards,Widgets & Layout.",
      type: "headtitle",
    },
    {
      title: "Dashboard",
      active: true,
      badgeType: "light-primary",
      badgeValue: null,
      children: [
        {
          active: false,
          path: "/dashboard/home",
          title: "Home",
          type: "link",
        },
        {
          active: false,
          path: "/dashboard/reports",
          title: "Reports",
          type: "link",
        },
      ],
      icon: "stroke-home",
      iconf: "fill-home",
      type: "sub",
    },
    {
      title: "Tracking",
      active: false,
      badgeType: "light-primary",
      badgeValue: null,
      children: [
        {
          active: false,
          path: "/tracking/shipment-movements",
          title: "Shipment Movements",
          type: "link",
        },
      ],
      icon: "stroke-maps",
      iconf: "fill-home",
      type: "sub",
    },
  ],
}
