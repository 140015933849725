<template>
  <div class="table-responsive signal-table">
    <slot
      name="table"
      :data="items"
    >
      <table class="table table-hover">
        <thead>
          <tr class="border-bottom-primary">
            <th
              v-for="header in headers"
              :key="header.key"
              :style="{ width: header.width || 'auto' }"
            >
              {{ header.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in items"
            :key="row.id"
            @click="handleRowClick(row)"
          >
            <td
              v-for="header in headers"
              :key="header.key"
              :style="{ width: header.width || 'auto' }"
            >
              <slot
                :name="header.key"
                :row="row"
                :index="index"
              >
                {{ row[header.key] }}
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
    </slot>
  </div>
  <b-row
    v-if="pagination"
    class="m-0 mt-3"
  >
    <b-col class="d-flex align-items-center">
      Total pages: {{ pagination?.totalPages }}
    </b-col>
    <b-col class="d-flex justify-content-end p-0">
      <b-row class="d-flex justify-content-end align-items-center m-0">
        <div class="d-flex align-items-center me-3">
          <select
            v-model="currentPerPage"
            class="form-select form-primary"
            @change="updatePerPage"
          >
            <option
              v-for="option in pageOptions"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </div>

        <ul class="pagination pagination-primary m-0 p-0">
          <li
            class="page-item"
            :class="{ disabled: pagination?.currentPage === 1 }"
            @click="firstPage"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
            >First Page</a>
          </li>
          <li
            v-for="page in pageSplit"
            :key="page"
            class="page-item"
            :class="{ active: page === pagination?.currentPage }"
            @click="changePage(page)"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
            >{{ page }}</a>
          </li>
          <li
            class="page-item"
            :class="{
              disabled: pagination?.currentPage === pagination?.totalPages,
            }"
            @click="lastPage"
          >
            <a
              class="page-link"
              href="javascript:void(0)"
            >Last Page</a>
          </li>
        </ul>
      </b-row>
    </b-col>
  </b-row>
</template>

<script setup>
import { data } from "@/data/button"
import { computed, ref, watch } from "vue"

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  headers: {
    type: Array,
    default: () => [],
  },
  pageOptions: {
    type: Array,
    default: () => [5, 10, 20, 50],
  },
  pagination: {
    type: Object,
    required: false,
    // default: () => ({
    //   totalItems: 0,
    //   itemCount: 0,
    //   itemsPerPage: 10,
    //   totalPages: 0,
    //   currentPage: 1,
    // }),
  },
})

const emit = defineEmits(["update:page", "update:perPage", "rowClick"])

const currentPerPage = ref(props.pagination?.itemsPerPage)

const pageSplit = computed(() => {
  const totalPages = props.pagination?.totalPages
  const currentPage = props.pagination?.currentPage

  if (totalPages <= 5) {
    return Array.from({ length: totalPages }, (_, i) => i + 1)
  }

  const startPage = Math.max(1, currentPage - 2)
  const endPage = Math.min(totalPages, currentPage + 2)

  const pages = []
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i)
  }

  return pages
})

function changePage(page) {
  if (page > 0 && page <= props.pagination?.totalPages) {
    emit("update:page", page)
  }
}

function firstPage() {
  if (props.pagination?.currentPage > 1) {
    changePage(1)
  }
}

function lastPage() {
  if (props.pagination?.currentPage < props.pagination?.totalPages) {
    changePage(props.pagination?.totalPages)
  }
}

function handleRowClick(row) {
  emit("rowClick", row)
}

function updatePerPage() {
  emit("update:perPage", currentPerPage.value)
}

watch(
  () => props.pagination?.itemsPerPage,
  (newPerPage) => {
    currentPerPage.value = newPerPage
  }
)
</script>

<style lang="scss" scoped>
td,
th {
  background-color: transparent !important;
}

:deep(svg) {
  margin: 0 !important;
}
</style>
