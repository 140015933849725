import { defineStore } from "pinia"
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import { useLoader } from "@/composables/useLoader"
import { toast } from "vue3-toastify"
import { getShipmentsKanban } from "@/pages/tracking/service/trackingService"
import { computed } from "vue"

const { incrementRequisition, decrementRequisition } = useLoader()

export const useHomeStore = defineStore("homeStore", () => {
  const { t } = useI18n()

  // States
  const shipments = ref(null)

  const filters = ref({
    refGotrans: null,
    creationDate: null,
    refClient: null,
    client: null,
    status: null,
    origin: null,
    destination: null,
    etd: null,
    eta: null,
  })

  const shipmentsByModal = computed(() => [
    {
      title: "CAI",
      value: shipments.value?.CAI,
      total: shipments.value?.CAI?.length ?? 0,
    },
    {
      title: "CAE",
      value: shipments.value?.CAE,
      total: shipments.value?.CAI?.length ?? 0,
    },
    {
      title: "CMI",
      value: shipments.value?.CMI,
      total: shipments.value?.CAI?.length ?? 0,
    },
    {
      title: "CME",
      value: shipments.value?.CME,
      total: shipments.value?.CAI?.length ?? 0,
    },
  ])

  const shipmentsTotalByModal = computed(() => {
    return {
      CAI: shipments.value?.CAI?.length ?? 0,
      CAE: shipments.value?.CAE?.length ?? 0,
      CMI: shipments.value?.CMI?.length ?? 0,
      CME: shipments.value?.CME?.length ?? 0,
    }
  })

  const totalShipments = computed(() => {
    if (!shipments.value) return 0

    const sumLengths = (keys) =>
      keys.reduce((total, key) => {
        return total + (shipments.value[key]?.length || 0)
      }, 0)

    return sumLengths(["CAI", "CAE", "CMI", "CME"])
  })

  // Methods
  const fetchShipments = async () => {
    try {
      incrementRequisition()

      const response = await getShipmentsKanban(filters.value)

      shipments.value = response.data

      if (response.message) {
        toast.warn(t("failedSearchShipmentMovements"))
      }
    } catch (error) {
      toast.warn(t("failedSearchShipmentMovements"))
    } finally {
      decrementRequisition()
    }
  }

  const clearFilters = () => {
    filters.value = {
      refGotrans: null,
      creationDate: null,
      refClient: null,
      client: null,
      status: null,
      origin: null,
      destination: null,
      etd: null,
      eta: null,
    }

    fetchShipments()
  }

  return {
    totalShipments,
    filters,
    shipmentsByModal,
    shipmentsTotalByModal,
    shipments,
    clearFilters,
    fetchShipments,
  }
})
