import apiClient from "@/plugins/axios"

const BASE_URL = "/powerembedded/reports"

const getReportUrl = async (userEmail, reportId) => {
  const response = await apiClient.post(`${BASE_URL}/report`, {
    userEmail,
    reportId,
  })
  return response
}

const getListEmbeddedReports = async () => {
  const response = await apiClient.get(`${BASE_URL}`)
  return response
}

const powerEmbeddedService = {
  getReportUrl,
  getListEmbeddedReports,
}

export default powerEmbeddedService
