<template>
  <div class="sh-modal">
    <ShipmentWidget
      :icon="iconType"
      :color="color"
      :title="total"
      :label="label"
    />
  </div>
</template>

<script setup>
import ShipmentWidget from "@/components/ShipmentWidget.vue";
import { computed } from "vue"

const props = defineProps({
  icon: String,
  color: String,
  total: Number,
  label: String,
})

const iconType = computed(() => {
  const modal = props.label

  if (modal === "CAI" || modal === "CAE") {
    return "plane"
  } else {
    return "ship"
  }
})
</script>
