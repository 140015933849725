import { defineStore, storeToRefs } from "pinia"
import { ref, computed } from "vue"
import { useI18n } from "vue-i18n"
import powerEmbeddedService from "../service/powerEmbeddedService"
import { useAuthStore } from "@/auth/store/authStore"
import { toast } from "vue3-toastify"
import router from "@/router"

export const useReportsStore = defineStore("useReportsStore", () => {
  const { t } = useI18n()

  const store = useAuthStore()
  const { userInfo } = storeToRefs(store)

  const reports = ref({})
  const reportUrl = ref({})

  const headers = computed(() => [
    {
      key: "actions",
      label: t("actions"),
      width: "75px",
    },
    {
      key: "name",
      label: t("name"),
    },
  ])

  const reportNames = computed(() => {
    return reports.value?.reportNames
  })

  const reportIds = computed(() => {
    return reports.value?.reportIds
  })

  const getReports = async () => {
    const response = await powerEmbeddedService.getListEmbeddedReports(
      userInfo.value.email
    )
    reports.value = response.data
  }

  const getReportUrl = async (index) => {
    const reportId = reportIds.value[index] ?? null

    if (!reportId) return toast.error(t("error"))

    router.push({
      name: "report",
      query: { report: reportId },
    })
  }

  return {
    reports,
    headers,
    reportNames,
    reportIds,
    reportUrl,
    getReports,
    getReportUrl,
  }
})
