<template>
  <Breadcrumbs
    :title="$t('reports')"
    router-name="reports"
  />

  <b-card>
    <div>
      <DataTable
        :items="reportNames"
        :headers="headers"
      >
        <template #actions="{ index }">
          <b-button
            to="/tracking"
            shape="round"
            @click="goToReportView(index)"
          >
            <vue-feather type="book-open"></vue-feather>
          </b-button>
        </template>
        <template #creationDate="{ row }">
          <span>{{ formatDate(row.creationDate) }}</span>
        </template>
        <template #status="{ row }">
          <span
            v-if="row.status"
            class="badge rounded-pill bg-primary f-14 m-0"
          >
            {{ row.status }}
          </span>
        </template>
        <template #etd="{ row }">
          <span>{{ formatDate(row.etd) }}</span>
        </template>
        <template #eta="{ row }">
          <span>{{ formatDate(row.eta) }}</span>
        </template>
      </DataTable>
    </div>
  </b-card>
</template>

<script setup>
import { onMounted } from "vue"
import { useLoader } from "@/composables/useLoader"
import { toast } from "vue3-toastify"
import { storeToRefs } from "pinia"
import { useI18n } from "vue-i18n"
import DataTable from "@/components/DataTable.vue"
import { useReportsStore } from "./store/reportsStore"

const { t } = useI18n()

const { incrementRequisition, decrementRequisition } = useLoader()

const store = useReportsStore()
const { reportNames, headers } = storeToRefs(store)
const { getReportUrl, getReports } = store

onMounted(async () => {
  try {
    incrementRequisition()
    await getReports()
  } catch (error) {
    toast.error(t("error"))
  } finally {
    decrementRequisition()
  }
})

const goToReportView = async (index) => {
  try {
    incrementRequisition()
    await getReportUrl(index)
  } catch (error) {
    toast.error(t("error"))
  } finally {
    decrementRequisition()
  }
}
</script>

<style>
iframe {
  height: 100vh;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
}

.div-report {
  width: 100%;
  height: 100%;
}
</style>
