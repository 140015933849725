<template>
  <b-card :title="t('result')">
    <div>
      <DataTable
        :items="shipments"
        :headers="headers"
        :pagination="pagination"
        :items-per-page="itemsPerPage"
        @update:page="updatePage"
        @update:perPage="updateItemsPerPage"
      >
        <template #actions="{ row }">
          <b-button
            outline
            to="/tracking"
            shape="round"
            @click="goToTracking(row)"
          >
            <vue-feather
              type="map"
              class="m-0"
            ></vue-feather>
          </b-button>
        </template>
        <template #creationDate="{ row }">
          <span>{{ formatDate(row.creationDate) }}</span>
        </template>
        <template #latestTimelineStatusDescription="{ row }">
          <span
            v-if="row.latestTimelineStatusDescription"
            class="badge rounded-pill bg-primary f-14 m-0"
          >
            {{ row.latestTimelineStatusDescription }}
          </span>
        </template>
        <template #etd="{ row }">
          <span>{{ formatDate(row.etd) }}</span>
        </template>
        <template #eta="{ row }">
          <span>{{ formatDate(row.eta) }}</span>
        </template>
      </DataTable>
    </div>
  </b-card>
</template>

<script setup>
import { useI18n } from "vue-i18n"
import DataTable from "@/components/DataTable.vue"
import { storeToRefs } from "pinia"
import { formatDate } from "@/utils/formatDate"
import { searchShipmentStore } from "../../store/searchShipmentStore"
import router from "@/router"

const { t } = useI18n()

const store = searchShipmentStore()

const { shipments, headers, pagination, itemsPerPage } = storeToRefs(store)
const { updatePage, updateItemsPerPage } = store

const goToTracking = async (shipment) => {
  if (!shipment) return

  router.push({
    name: "tracking",
    query: {
      shipmentId: shipment.shipmentId,
      refGotrans: shipment.refGotrans,
    },
  })
}
</script>

<style scoped>
:deep(svg) {
  margin-right: 0 !important;
}

th,
td {
  background-color: transparent !important;
}

a {
  height: 30px;
  background-color: transparent;

  :hover {
    color: var(--white);
  }
}
</style>
