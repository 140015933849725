<template>
  <div :id="uuid">
    <div
      v-show="!hideCard"
      class="card p-4 widget-1 f-light w-100 gap-1 animated fadeInLeft"
    >
      <div class="d-flex justify-content-between align-items-center mb-4 w-100">
        <div>
          <font-awesome-icon
            :icon="['fas', ship ? 'ship' : 'plane']"
            class="mr-2"
          />
          <span class="fw-bold">{{ shipment?.refGotrans }}</span>
        </div>
        <div class="d-flex align-items-center gap-2">
          <span
            class="badge"
            style="font-size: 9px"
            :style="{ background: color + 'BF' }"
          >
            {{ status }}
          </span>
          <router-link
            :to="trackingLink"
            class="icon-link-custom"
          >
            <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" />
          </router-link>
        </div>
      </div>

      <!-- Client Badge -->
      <div class="d-flex align-items-center gap-2">
        <span class="label-card">Client:</span>
        <span
          class="badge"
          :style="{ background: color }"
        >
          {{ clientDisplay }}
        </span>
      </div>

      <!-- Origin and Destination -->
      <div class="d-flex align-items-center gap-2">
        <span class="label-card">Origin:</span>
        <span>{{ shipment?.origin }}</span>
      </div>
      <div class="d-flex align-items-center gap-2">
        <span class="label-card">Destination:</span>
        <span>{{ shipment?.destination }}</span>
      </div>

      <!-- Dates (ETD and ETA) -->
      <div class="d-flex flex-row justify-content-between">
        <div class="col col-6 d-flex align-items-center gap-2">
          <div
            class="label-card d-flex justify-content-center align-items-center gap-1"
          >
            <font-awesome-icon :icon="['far', 'calendar']" />
            <span>ETD:</span>
          </div>
          <span>{{ formatDate(shipment?.etd) }}</span>
        </div>
        <div class="col col-6 d-flex align-items-center gap-2">
          <div
            class="label-card d-flex justify-content-center align-items-center gap-1"
          >
            <font-awesome-icon :icon="['far', 'calendar']" />
            <span>ETA:</span>
          </div>
          <span>{{ formatDate(shipment?.eta) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted } from "vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { SHIPMENT_MODALS } from "@/constants/shipmentModals"
import { formatDate } from "@/utils/formatDate"
import { ref } from "vue"
import { v4 as uuidv4 } from "uuid"
import { useElementVisibility } from "@vueuse/core"

const props = defineProps({
  shipment: Object,
  ship: Boolean,
  root: String,
  index: Number,
})

const uuid = ref(uuidv4())
const target = ref(null)

onMounted(() => {
  target.value = document.getElementById(uuid.value)
})

const targetIsVisible = ref(useElementVisibility(target))

const hideCard = computed(() => {
  return !targetIsVisible.value && props.index > 5
})

const clientDisplay = computed(() => {
  const clientString = props.shipment?.client || ""
  return clientString.length < 25
    ? clientString
    : `${clientString.substring(0, 25)}...`
})

const status = computed(() => {
  const statusSring = props.shipment?.latestTimelineStatusDescription || ""
  return statusSring.length < 20
    ? statusSring
    : `${statusSring.substring(0, 20)}...`
})

const color = computed(() => {
  return SHIPMENT_MODALS[props.shipment?.modal]?.color ?? "#7366ff"
})

const trackingLink = computed(() => ({
  name: "tracking",
  query: {
    shipmentId: props.shipment?.shipmentId,
    refGotrans: props.shipment?.refGotrans,
  },
}))
</script>

<style scoped lang="scss">
.card {
  box-shadow: none !important;
  background-color: var(--white) !important;
  border: var(--view-border-marker) !important;
}

body.dark-only .card {
  background-color: #262932 !important;
}

.icon-link-custom,
.label-card {
  font-size: 13px;
  font-weight: 700;
  svg {
    padding-bottom: 1.5px;
  }
}

svg {
  color: v-bind(color) !important;
}

.badge {
  padding: 0.25em 0.6em;
  border-radius: 0.25rem;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1.2;
  background-color: #eee;
}
</style>
