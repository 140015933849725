<template>
  <h6>Filters:</h6>
  <div class="d-flex  align-items-center gap-2">
    <div class="d-flex flex-row gap-2">
      <div
        v-for="field in moreFiltersFields"
        :key="field.id"
        cols="2"
      >
        <input
          :id="field.id"
          v-model.trim="filters[field.model]"
          class="form-control btn-pill"
          :placeholder="`${t(field.label)}:`"
          :type="field.type || 'text'"
        />
      </div>
    </div>

    <div class="d-flex jusfiy-content-end gap-2">
      <b-button
        outline
        shape="round"
        @click="fetchShipments"
      >
        <vue-feather
          class="p-1"
          type="search"
          size="22"
        ></vue-feather>
      </b-button>
      <b-button
        outline
        shape="round"
        @click="clearFilters"
      >
        <vue-feather
          class="p-1"
          type="trash-2"
          size="22"
        ></vue-feather>
      </b-button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from "vue"
import { useI18n } from "vue-i18n"
import { useHomeStore } from "../store/homeStore"
import { storeToRefs } from "pinia"

const { t } = useI18n()

const store = useHomeStore()
const { filters } = storeToRefs(store)
const { fetchShipments, clearFilters } = store

const moreFiltersFields = [
  {
    id: "refGotrans",
    label: "Ref. Go-Trans",
    model: "refGotrans",
  },
  // {
  //   id: "creationDate",
  //   label: "creationDate",
  //   model: "creationDate",
  //   type: "date",
  // },
  {
    id: "refClient",
    label: `${t("refClient")}`,
    model: "refClient",
  },
  {
    id: "client",
    label: "client",
    model: "client",
  },
  {
    id: "status",
    label: "status",
    model: "status",
  },
  // {
  //   id: "origin",
  //   label: "origin",
  //   model: "origin",
  // },
  // {
  //   id: "destination",
  //   label: "destination",
  //   model: "destination",
  // },
  // {
  //   id: "etd",
  //   label: "etd",
  //   model: "etd",
  //   type: "date",
  // },
  // {
  //   id: "eta",
  //   label: "eta",
  //   model: "eta",
  //   type: "date",
  // },
]

const handleKeyPress = (event) => {
  if (event.key === "Enter") {
    fetchShipments()
  }
}

onMounted(() => {
  fetchShipments()
  window.addEventListener("keydown", handleKeyPress)
})

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeyPress)
})
</script>

<style scoped>
input {
  height: 34px;
}
.card {
  box-shadow: none;
}
</style>
