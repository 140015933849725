import { defineStore } from "pinia"
import { ref, computed } from "vue"
import { useI18n } from "vue-i18n"
import { useLoader } from "@/composables/useLoader"
import { getShipments } from "../service/trackingService"
import { toast } from "vue3-toastify"

const { incrementRequisition, decrementRequisition } = useLoader()

export const searchShipmentStore = defineStore("searchShipmentStore", () => {
  const { t } = useI18n()

  // States
  const shipments = ref([])
  const pagination = ref({
    itemsPerPage: 10,
    currentPage: 1,
  })

  const filters = ref({
    refGotrans: null,
    creationDate: null,
    refClient: null,
    client: null,
    status: null,
    origin: null,
    destination: null,
    etd: null,
    eta: null,
  })

  const headers = computed(() => [
    {
      key: "actions",
      label: t("timeline"),
    },
    {
      key: "refGotrans",
      label: "Ref. Go-Trans",
    },
    {
      key: "creationDate",
      label: t("creationDate"),
    },
    {
      key: "refClient",
      label: t("refClient"),
    },
    {
      key: "client",
      label: t("client"),
    },
    {
      key: "latestTimelineStatusDescription",
      label: t("status"),
    },
    {
      key: "origin",
      label: t("origin"),
    },
    {
      key: "destination",
      label: t("destination"),
    },
    {
      key: "etd",
      label: t("ETD"),
    },
    {
      key: "eta",
      label: t("ETA"),
    },
  ])

  // Methods
  const fetchShipments = async () => {
    try {
      incrementRequisition()

      const response = await getShipments(filters.value, pagination.value)

      shipments.value = response.data.items
      pagination.value = response.data.meta

      if (response.message) {
        toast.warn(t("failedSearchShipmentMovements"))
      }
    } catch (error) {
      toast.warn(t("failedSearchShipmentMovements"))
    } finally {
      decrementRequisition()
    }
  }

  const clearFilters = () => {
    filters.value = {
      refGotrans: null,
      creationDate: null,
      refClient: null,
      client: null,
      status: null,
      origin: null,
      destination: null,
      etd: null,
      eta: null,
    }

    pagination.value = {
      itemsPerPage: 10,
      currentPage: 1,
    }

    fetchShipments()
  }

  const updatePage = (page) => {
    pagination.value.currentPage = page
    fetchShipments()
  }

  const updateItemsPerPage = (perPage) => {
    pagination.value.itemsPerPage = perPage
    fetchShipments()
  }

  return {
    filters,
    headers,
    shipments,
    pagination,
    clearFilters,
    fetchShipments,
    updateItemsPerPage,
    updatePage,
  }
})
